<template>
    <modal ref="modalCambiarImagen" titulo="Cambiar Imagen" no-aceptar adicional="Guardar" @adicional="changeBanner()">
            <div class="row mx-0 my-5 j-center">
                <div class="position-relative d-middle-center w-100 border br-4 bg-gris2">
                    <div class="position-absolute text-center">
                        <img src="/img/estados-vacios/anadir_imagen.svg" width="160" height="90" />
                        <p class="text-center">Arrastra una imagen <br />
                        o haga clic aquí</p>
                    </div>
                    <slim-cropper
                    ref="bannerService"
                    :options="slimOptions"
                    class="border cr-pointer w-100"
                    style="background:#F8F9FF;border-radius:4px;z-index:4;"
                    />
                </div>
            </div>
        </modal>
</template>
<script>
import Servicios from '~/services/tiendas'
import tiendas from '../../../router/tiendas/tiendas';
export default {
    data(){
        return {
            number: 1,
            idServicio : this.$route.params.idServicio,
            banner: null,
            nuevoBanner: null,
            slimOptions: {
              ratio: "16:9",
              label: "Subir imagen",
              buttonCancelLabel: "Cancelar",
              buttonConfirmLabel: "ok",
              buttonUploadLabel: "Ok",
      },
        }
    },
    props: {
        bannerOriginal: {
            type: String,
            default: null
        }
    },
    watch:{
        bannerOriginal(){
          this.$refs.bannerService.set_image(`${this.bannerOriginal}`)
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCambiarImagen.toggle();
        },
        async changeBanner(){
          try{
          this.banner = this.$refs.bannerService.get_image()
          const payload = {
              idServicio: this.idServicio,
              valor : this.banner,
              campo: 'banner'
            }

          const { data } = await Servicios.actualizarInfoEnCaliente(payload)
          this.notificacion('Éxito', data.mensaje, 'success')
          this.$emit("actualizarBanner", this.banner)
          this.$refs.modalCambiarImagen.toggle();
          } catch (error) {
            this.error_catch(error)
          }
        },
    }
}
</script>